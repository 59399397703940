
import axios from 'axios'
import { ImageSetInfo, flattenJob } from '@/models/job'
import { Component, Prop } from 'vue-property-decorator'
import { approveJob, Type } from '@/approve'
import Base from '@/pages/JobBatchBase.vue'
import { flagForEditing } from '@/flag'

@Component
export default class extends Base {
  @Prop() account!: string
  @Prop() job!: string
  accountName = ''

  created () {
    this.type = Type.Job

    this.dataFn = async () => {
      const url = `${ process.env.VUE_APP_ACCOUNTS_API }/accounts/${ this.account }/jobs/${ this.job }`
      const key = process.env.VUE_APP_ACCOUNTS_API_KEY
      const res = await axios.get<ImageSetInfo>(url, { params: { key } })
      this.accountName = res.data.schoolName
      this.jobImages = flattenJob(res.data)

      let title = `${ this.account }/${ this.job }`.toUpperCase()
      if (this.accountName !== '') {
        title += ` | ${ this.accountName }`
      }
      this.toolbarTitle = title
    }

    this.flagFn = (longRefs, flagText) => {
      console.log('Flagging batch images: ', longRefs, 'for edit')
      return flagForEditing(longRefs, flagText, 'S')
    }

    this.closeFn = () => { this.$router.push('/'); return Promise.resolve() }

    this.approveFn = () => approveJob(this.adjustedImages, this.account, this.job)

    this.imageNotFoundFn = () => Promise.resolve()

    this.loadData()
  }
}
